export default {
  currency: {
    useConversions: true,
    default: "ILS",
    availableCurrencies: ["ILS"],
  },
  productTypes: {
    activeProductTypes: [
      { value: "multiVariant", label: "ag.ecommerce.multiVariantType.uiTypeLabel" },
    ],
  },
  order: {
    statuses: ["new", "canceled", "payed", "approved", "completed"],
  },
};
