export default {
  types: {
    term: {
      routeFunction: (item) => ({
        name: "content-term",
        params: {},
        query: { title: item.title },
      }),
    },
  },
};
