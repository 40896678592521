module.exports = {
  name: "Agaloola",
  iconPaths: {
    favicon32: "agaloola/images/favicons/favicon-32x32.png",
    favicon16: "agaloola/images/favicons/favicon-32x32.png",
    appleTouchIcon: "agaloola/images/favicons/apple-touch-icon-152x152.png",
    maskIcon: "agaloola/images/favicons/safari-pinned-tab.svg",
    msTileImage: "agaloola/images/favicons/msapplication-icon-144x144.png",
  },
  themeColor: "#1a535c",
};
